import { T } from "@/locales";

export default {
  number: [
    { required: true, message: T('请输入仓库编号'), trigger: 'change' },
    { max: 32, message: T('超出最大长度 (32)'), trigger: 'change' },
  ],
  name: [
    { required: true, message: T('请输入仓库名称'), trigger: 'change' },
    { max: 64, message: T('超出最大长度 (64)'), trigger: 'change' },
  ],
  remark: [{ max: 256, message: T('超出最大长度 (256)'), trigger: 'change' }],
}